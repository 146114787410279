import React, { Fragment } from 'react'
import { Link } from 'gatsby'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import Servizio from '../../components/Servizio'

import LogisticsImg from '../../images/logistics.jpg'

const paginaServizio = {
  titolo: 'Logistics',
  parent: null,
  servizi: [
    {
      id: 1,
      nome: 'Camionistico',
      imgUrl: LogisticsImg,
      paragrafi: [
        {
          id: 1,
          testo:
            'Logistics Groupage è il servizio tradizionale camionistico “door to door” per l’Europa per spedizioni costituite da uno o più colli/bancali senza limiti di peso e dimensioni, con tempi di resa di 72-96 ore.',
        },
      ],
      pesoDimensioni: [
        {
          id: 1,
          descrizione: 'Peso massimo per collo',
          valore: '	No limit',
        },
        {
          id: 2,
          descrizione: 'Lunghezza massima',
          valore: '	No limit',
        },
      ],
      vantaggi: [
        {
          id: 1,
          testo: 'Consegna door-to-door in tutta Europa',
        },
        {
          id: 2,
          testo: 'Senza limiti di peso, dimensioni e volumi',
        },
        {
          id: 3,
          testo: 'Tempi di consegna regolari in 72 o 96 ore',
        },
        {
          id: 4,
          testo: 'Assicurazione inclusa secondo la convenzione di CMR',
        },
        {
          id: 5,
          testo: 'Consegna anche presso stand fieristici',
        },
        {
          id: 6,
          testo:
            'Prenotazione del servizio tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: null,
    },
    {
      id: 2,
      nome: 'Cargo aereo',
      imgUrl: '',
      paragrafi: [
        {
          id: 1,
          testo:
            'Logistics Airfreight è il servizio aereo per merci senza limiti di peso e dimensione, per consegne in tutto il mondo in 72-96 ore fermo aeroporto di destino con possibilità di consegne door-to-door in numerose Località e Nazioni.',
        },
      ],
      pesoDimensioni: [
        {
          id: 1,
          descrizione: 'Peso massimo per spedizione',
          valore: '	No limit',
        },
        {
          id: 2,
          descrizione: 'Lunghezza massima',
          valore: '274 cm',
        },
      ],
      vantaggi: [
        {
          id: 1,
          testo: 'Consegne door-to-door in tutto il mondo',
        },
        {
          id: 2,
          testo: 'Nessun limite di peso, dimensioni e volumi',
        },
        {
          id: 3,
          testo:
            'Tempi di consegna: 72 o 96 ore al più vicino aeroporto di destino',
        },
        {
          id: 4,
          testo:
            'Assicurazione inclusa secondo gli accordi della Convenzione di Varsavia',
        },
        {
          id: 5,
          testo:
            'Prenotazione del servizio tramite telefono, e-mail, Weblabeling (per maggiori informazioni contatta la tua Sede di riferimento), o eventualmente via fax',
        },
      ],
      compatibileCon: null,
    },
  ],
}

const Logistics = () => {
  return (
    <Layout>
      <SEO
        title={paginaServizio.titolo}
        description={paginaServizio.servizi[0].paragrafi[0].testo}
      />
      <section className="py-8 px-6">
        <h1 className="text-3xl sm:text-4xl font-light text-gls-blue-900 leading-tight">
          {!!paginaServizio.parent && (
            <Link
              to={paginaServizio.parent.linkUrl}
              className="mr-2 text-lg text-gls-blue-600 hover:text-gls-blue-700"
            >
              {paginaServizio.parent.nome} &raquo;
            </Link>
          )}
          {paginaServizio.titolo}
        </h1>
      </section>
      <section className="bg-white lg:rounded-lg shadow-xl">
        {paginaServizio.servizi.map((servizio, index) => {
          return (
            <Fragment key={servizio.id}>
              <Servizio servizio={servizio} />
              {index < paginaServizio.servizi.length - 1 && <hr />}
            </Fragment>
          )
        })}
      </section>
    </Layout>
  )
}

export default Logistics
